
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import {
  getHardwareVersionList,
  editHardwareVersion,
  addHardwareVersion,
  isEditAuthByCode,
  getModelList
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  customerData: Array<any>
  editFlag: boolean
  editForm: any
  modelOption:any
  searchDataAny: any //搜索条件
}

export default defineComponent({
  name: 'hardwareVersionTable',
  components: {},
  setup() {
    const objRef = ref()
    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件

      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      modelOption: [],
      rules: {
        tbDevModelId: [{ required: true, message: '请输入规格名称', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
            getModel();
              getList()
          },50);
    })

    const getModel = () => {
      getModelList({ isPage: false }).then(
        (res: any) => {
            if (res.data != null) {
                for (let o of res.data.list) {
                    console.info("xxxxxxxxxxxxxxxx");
                    deviceData.modelOption.push({
                    value: o.id,
                    label: o.name
                });
                }
            }
        }
      )
    }

    const addFun = () => {
        deviceData.editForm = { isEdit: false }      
      deviceData.editFlag = true
    }

    const editSave = async () => {
      objRef.value.validate(async (valid: any) => {
        if (valid) {
          let fun = addHardwareVersion
          if (deviceData.editForm.isEdit) {
            fun = editHardwareVersion
            delete deviceData.editForm.createTime
          }

          let array: any = {}
          Object.assign(array, deviceData.editForm)
            console.log("xxxx");
          if(deviceData.editForm.alarmSwitch!=null){
              array.alarmSwitch = deviceData.editForm.alarmSwitch + ''
          }
          
          if(deviceData.editForm.hibernates!=null){
              array.hibernates = deviceData.editForm.hibernates + ''
          }
          
          let keys = Object.keys(array);
          for (let key of keys) {
              if(array[key] == null && key !='alarmSwitch' && key !='hibernates'){
                array[key] = 0;
              }
          }

          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }

    //编辑
    const editorFun = (e: any) => {
      //console.log(e)
      let obj: any = {}
      Object.assign(obj, e)

      deviceData.editForm = obj
      deviceData.editForm.isEdit = true
      let name;
      for(let model of deviceData.modelOption){
        if(obj.tbDevModelId == model.value){
          name = model.label;
          break
        }
      }
      deviceData.modelOption.push({
          value: obj.tbDevModelId,
          label: name,
        });
      deviceData.editFlag = true
    }

    const getList = async () => {
        //deviceData.customerData = [];
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getHardwareVersionList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
            // ElMessage.success('加载成功!')
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }
    
    const getName = (val: any) => {
      for(let model of deviceData.modelOption){
        if(val == model.value){
          return model.label;
        }
      }
    }
    return {
      ...data,
      addFun,
      objRef,
      editSave,
      selectCustomer,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
      isEditAuthByCode,
      getName
    }
  },
})
